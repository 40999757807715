/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-07-24",
    versionChannel: "nightly",
    buildDate: "2024-07-24T00:05:06.800Z",
    commitHash: "a7ee776750475ff9afd0decd24c70a8631820143",
};
